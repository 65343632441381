import { EXTERNAL_CATEGORY, CONTENTFUL_DATA_API_URL, CONTENTFUL_DATA_API_URL_DEV, CONTENTFUL_DATA_API_URL_STAGE, CONTENTFUL_DATA_API_URL_UAT, DISW_LOGIN } from './constants';
import { DEFAULT_LOCALE_CODE } from '../../disw-utils/src/constants/locales';
export function renderTarget(category) {
    if (category === EXTERNAL_CATEGORY) {
        return {
            target: '_blank',
            rel: 'noopener noreferrer'
        };
    }
    return {};
}
// Cache the nav requests
const navCache = {};
// Make function async to ensure this function returns a promise
export async function fetchNavData(slug, locale = DEFAULT_LOCALE_CODE, env = 'prod') {
    const cacheKey = `${slug}_${locale}`;
    const nav = navCache[cacheKey];
    let globalNavApiUrl = '';
    if (env === 'qa' || env === 'dev') {
        globalNavApiUrl = CONTENTFUL_DATA_API_URL_DEV;
    }
    else if (env === 'uat') {
        globalNavApiUrl = CONTENTFUL_DATA_API_URL_UAT;
    }
    else {
        globalNavApiUrl = CONTENTFUL_DATA_API_URL;
    }
    // If a nav has been found in cache wether it is loading or loaded return it
    // This prevents duplicate requests
    if (nav) {
        return nav;
    }
    // Set the navCache for the slug_locale
    // Return the promise as the value of the cache
    navCache[cacheKey] = fetch(`${globalNavApiUrl}navs/${slug}/${locale}`, {
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json());
    return navCache[cacheKey];
}
// const navCache: Map<string, any> = new Map();
// // Make function async to ensure this function returns a promise
// export async function fetchNavData(slug: string, locale = DEFAULT_LOCALE_CODE, env = 'prod') {
//   const cacheKey = `${slug}_${locale}`;
//   const nav = navCache.get(cacheKey);
//   let globalNavApiUrl = '';
//   if (env === 'qa' || env === 'dev') {
//     globalNavApiUrl = CONTENTFUL_DATA_API_URL_DEV;
//   } else if (env === 'uat') {
//     globalNavApiUrl = CONTENTFUL_DATA_API_URL_UAT;
//   } else {
//     globalNavApiUrl = CONTENTFUL_DATA_API_URL;
//   }
//   // If a nav has been found in cache wether it is loading or loaded return it
//   // This prevents duplicate requests
//   if (nav) {
//     return nav;
//   }
//   // Set the navCache for the slug_locale
//   // Return the promise as the value of the cache
//   navCache.set(cacheKey, fetch(`${globalNavApiUrl}navs/${slug}/${locale}`, {
//     mode: 'cors',
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   }).then(response => response.json()));
//   return navCache.get(cacheKey);
// }
/**
 * A helper function to build url with query parameters
 * @param {String} url A string value representing url
 * @param {Array} queryParameters An array of objects value representing each querystring key value. For example: [{key='id',value='1234'}]
 * @returns A String url or a url with query parameters
 */
export function buildUrl(url, queryParameters = []) {
    if (queryParameters.length === 0) {
        return url;
    }
    return url.indexOf('?') !== -1 ? `${url}&${queryParameters.map(e => `${e.key}=${e.value}`).join('&')}` : `${url}?${queryParameters.map(e => `${e.key}=${e.value}`).join('&')}`;
}
//Implemented the actual data retrieval mechanism from the CDN
export function fetchLocalNavData(slug, locale = DEFAULT_LOCALE_CODE, env = 'prod') {
    let navAPIURL = '';
    switch (env) {
        case 'prod':
            navAPIURL = CONTENTFUL_DATA_API_URL;
            break;
        case 'stage':
            navAPIURL = CONTENTFUL_DATA_API_URL_STAGE;
            break;
        case 'qa':
            navAPIURL = CONTENTFUL_DATA_API_URL_DEV;
            break;
        case 'uat':
            navAPIURL = CONTENTFUL_DATA_API_URL_UAT;
            break;
        default:
            navAPIURL = CONTENTFUL_DATA_API_URL_DEV;
    }
    if (env !== 'prod') {
        console.log('navApiUrl', navAPIURL, env);
    }
    return fetch(`${navAPIURL}localnavs/fetch/${slug}/${locale}`, {
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json());
}
/**
 * Check a value to see if it's true or false
 *
 * @param {value} value we want to determine the boolean of
 * @param {true|false} defaultValue if we can't determine the boolean quickly, just return the default
 * @returns {true|false}
 */
export function checkBool(value, defaultValue) {
    // If boolean, we're good to go
    if (typeof value === 'boolean') {
        return value;
    }
    // If string, check if it's 'true'
    if (typeof value === 'string') {
        return value.toLowerCase() === 'true';
    }
    // Otherwise, use the default
    return defaultValue;
}
/**
 * Format the currency for display based on the locale, currency and input price.
 *
 * @example <caption>Example Usage</caption>
 *  formatCurrency('en-US', 'USD', 3600) yields output = '$3,600'
 *
 * @param {string} locale for the currency (i.e., 'en-US')
 * @param {string} currency for the currency (i.e., 'USD')
 * @param {number} price being formatted
 * @returns price formatted with currency symbol
 */
export function formatCurrency(locale = 'en-US', currency = 'USD', price = 0) {
    price = price < 0 ? 0 : price;
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
    return formatter.format(price);
}
export function getCurrencySymbol(locale = 'en-US', currency = 'USD') {
    return (0)
        .toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })
        .replace(/\d/g, '')
        .trim();
}
/**
 * Debounce function for scenarios where function should hold off executing until repeated user action
 * has concluded. When the function is called a timer counts down until either the timer runs out, or the function
 * is called again which resets the timer.
 *
 * @param {*} func function which will be executed if the timer runs out (repeated actions end).
 * @param {*} timeout time between repeated actions
 * @returns
 */
export function debounce(func, timeout = 600) {
    let timer;
    return function bounce(...args) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}
/**
 * Add a query parameter into the url without reloading via the history.replaceState API. URL will be updated
 * without a refresh of the page.
 *
 * @param {string} paramName of the query parameter being added to the URL.
 * @param {string} paramValue to be added as the value for the param name..
 */
export function addQueryParameterToUrl(paramName, paramValue = '') {
    const params = new URLSearchParams(window.location.search);
    if (!params.has(paramName)) {
        params.append(paramName, '');
        window.history.pushState({ [paramName]: paramValue }, '', `?${params}`);
        // Make sure to update the login href when fullCart parameter is added
        if (document.getElementById(DISW_LOGIN)) {
            const { disw } = window;
            document.getElementById(DISW_LOGIN).href = disw.account.getLoginUrl();
        }
    }
}
/**
 * Remove a query parameter from the url without reloading via the history.replaceState API. URL will be updated
 * without a refresh of the page.
 *
 * @param {string} paramName of the query parameter being removed from the URL.
 */
export function removeQueryParameterFromUrl(paramName) {
    const params = new URLSearchParams(window.location.search);
    params.delete(paramName);
    window.history.pushState({ [paramName]: '' }, '', params.toString() === '' ? window.location.href.replace(window.location.search, '') : `?${params}`);
    //Make sure to update the login href when fullcart prameter is removed
    if (document.getElementById(DISW_LOGIN)) {
        const { disw } = window;
        document.getElementById(DISW_LOGIN).href = disw.account.getLoginUrl();
    }
}
/**
 *  Truncates title or link to 55 characters if exceeds
 *
 * @param truncText
 * @param maxTextLength
 * @returns
 */
export function truncateTextFn(truncText, maxTextLength) {
    if (truncText.length >= maxTextLength) {
        return `${truncText.slice(0, maxTextLength - 1)}...`;
    }
    return truncText;
}
/*
 * Build the URL for the Checkout Router Service process. Get the base URL and append all data needed by the Checkout
 * Service to go through its workflow.
 *
 * @param {boolean} marketingCheckoutEnabled a boolean indicating whether to route to the marketing checkout experience or to the DEX checkout.
 * @param cartId the cartId of the current cart
 * @returns {String} Full URL to access the DEX Checkout Service
 */
export function getDexCheckoutServiceUrl(marketingCheckoutEnabled, cartId) {
    var _a, _b;
    const checkoutServiceURL = new URL(window.disw.cart.getCartCheckoutServiceUrl());
    const account = window.disw.account.getAccount();
    // Add the custom params
    checkoutServiceURL.search = new URLSearchParams({
        // add token as member only if jwt is present
        ...(((_a = account === null || account === void 0 ? void 0 : account.user) === null || _a === void 0 ? void 0 : _a.jwt) && { __tkn: (_b = account === null || account === void 0 ? void 0 : account.user) === null || _b === void 0 ? void 0 : _b.jwt }),
        cartId,
        cartCountry: window.disw.country.getCountry().countrycode,
        returnToUrl: window.location.href,
        timeStamp: Date.now(),
        marketingCheckoutExperience: marketingCheckoutEnabled ? 'enabled' : 'disabled'
    }).toString();
    return checkoutServiceURL.href;
}
/**
 * Determines the site checkout URL for the new checkout experience for the current site.
 * Works by determining the site domain and locale, and combining them to get the checkout URL for the given site.
 */
export function getOnSiteMarketingCheckoutURL() {
    const urlPieces = window.location.href.split('/');
    // We know that the protocol will be the first part of the url pieces (hopefully it's https ;)
    // The : will be included, because only the slashes are being removed
    const httpProtocol = urlPieces[0];
    // We know that the domain will be the 3rd url piece
    const siteDomain = urlPieces[2];
    // We know that the locale will be the 4th url piece
    const locale = urlPieces[3];
    // Now piece together the checkout URL
    return `${httpProtocol}//${siteDomain}/${locale}/checkout/`;
}
