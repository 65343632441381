import { BASE_CDN_URL, BASE_CDN_URL_DEV, BASE_CDN_URL_STAGE, BASE_CDN_URL_UAT } from '../../disw-utils/src/constants/api_urls';
// DISW CDN URL
export const CDN_URL = `${BASE_CDN_URL}/disw`;
// Contentful Data API URL
export const CONTENTFUL_DATA_API_URL = `${BASE_CDN_URL}/api/`;
export const CONTENTFUL_DATA_API_URL_DEV = `${BASE_CDN_URL_DEV}/api/`;
export const CONTENTFUL_DATA_API_URL_STAGE = `${BASE_CDN_URL_STAGE}/api/`;
export const CONTENTFUL_DATA_API_URL_UAT = `${BASE_CDN_URL_UAT}/api/`;
export const DISW_LOGIN = 'disw-profile-signin';
export const EXTERNAL_CATEGORY = 'External';
export const SAAS_CATEGORY = 'SasS';
export const LOCALNAV_CONTEXT_ID = 'lnc';
// Contact Sales
export const CONTACT_SALES_STRING = 'contact sales';
export const COMMERCE_BTN = 'commerce';
// REGEX used for locale
export const LOCALE_REGEX = /[a-z]{2}-[A-Z]{2}/g;
export const MILLISECONDS_PER_SECOND = 1000;
// OneTrust Cookie Category (marketing cookies AKA targeting cookies)
export const COOKIE_CATEGORY_TARGETING = 'targ';
// Grouped header items per column
export const MAX_ITEMS_PER_COLUMN = 8;
// Truncation Lengths
export const TEXT_MAX_LENGTH = 55;
